.upload-page-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  gap: 20vh;
}

.upload-portal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 20px;
  height: 4.5rem;
  border-bottom: 1px solid lightgray;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Inter, system-ui, Avenir, Helvetica, Arial, sans-serif';
  cursor: pointer;
}

.container-file-upload {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.select-type-of-file-container {
  margin: 0px 0px 50px 0px;
  width: 18%;
}

.data-corruct-error-container {
  position: relative;
}

.error-modal-close-button {
  font-size: 12px;
  color: red;
  background-color: white;
  position: absolute;
  top: 3%;
  right: 5%;
  border-radius: 40px;
  padding: 10px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  font-weight: 700;
  cursor: pointer;
  border: solid 1px black;
}

.accordian-container {
  width: 100%;
  margin: auto;
  max-height: 100vh;
  overflow-y: scroll;
  margin: 2rem 0 0;
}

.accordian-container::-webkit-scrollbar {
  width: 16px;
}

.accordian-container::-webkit-scrollbar-track {
  background-color: #242424;
}

.accordian-container::-webkit-scrollbar-thumb {
  background-color: #d4aa70;
  border-radius: 100px;
}

.file-btn {
  width: 330px;
  height: 150px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: purple;
  background-color: #fff;
  border: 1.5px dashed purple;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.file-btn:hover {
  color: purple;
  background-color: #fff;
}

.file-btn span {
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: purple;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #f1efff;
}

.file-card {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000;
  background-color: #fff;
  border: 1px solid rgba(117, 96, 255, 0.281);
  border-radius: 6px;
  padding: 8px 15px;
}

.file-info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;
}

.file-info h6 {
  flex: 1;
  font-size: 13px;
  font-weight: 400;
}

.progress-bg {
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.076);
  border-radius: 8px;
  margin-top: 8px;
}

.progress {
  width: 0%;
  height: 5px;
  background-color: purple;
  border-radius: 8px;
  transition: width 0.5s ease;
}

.icon {
  font-size: 30px;
  color: darkmagenta;
}

.close-icon {
  font-size: 18px;
  cursor: pointer;
}

.file-info button,
.check-circle {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #463a99;
  background-color: #f1efff;
  border: none;
  border-radius: 30px;
}

.upload-btn {
  width: 330px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.upload-btn:disabled {
  cursor: not-allowed;
  background-color: gray !important;
}
